import { configureStore } from '@reduxjs/toolkit';
import streamReducer from './stream/stream-slice';
import chatReducer from './chat/chat-slice';

const store = configureStore({
  reducer: {
    chat: chatReducer,
    stream: streamReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Игнорируем определенные пути состояния, где могут быть несериализуемые данные
        ignoredActions: ['stream/setPeerConnection'],
        ignoredPaths: ['stream.peerConnection'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
