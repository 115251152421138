import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { startVolume } from '../../../store/stream/stream-slice';
import { AppDispatch } from '../../../store/store';
import { establishConnection } from '../../../store/stream/stream-operations';



const Agree: React.FC = () => {





  return (
    <>
      asdasda12414
    </>
  );
};

export default Agree;
