import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store, { RootState, AppDispatch } from '../../../store/store';
import { setButtonActive } from '../../../store/stream/stream-slice';
import { setIsSkipped, setIsStreamingLoading, setMicroButton } from '../../../store/chat/chat-slice';
import { getIsSkipped } from 'store/chat/chat-selectors';
import { getIsClicked, getIsOverlayVisible, getIsStartVolume } from '../../../store/stream/stream-selectors';



const VideoWrapper: React.FC = () => {
  const startVideoRef = useRef<HTMLVideoElement | null>(null);
  const bodyVideoRef = useRef<HTMLVideoElement | null>(null);
  const talkVideoRef = useRef<HTMLVideoElement | null>(null); // Видео для стрима
  const dispatch: AppDispatch = useDispatch();
  const isVolume = useSelector((state: RootState) => state.stream.isStartVolume);
  const isStreaming = useSelector((state: RootState) => state.stream.isStreaming);
const isOverlayVisible = useSelector(getIsOverlayVisible)
  const [isStreamPlaying, setIsStreamPlaying] = useState(false); // Показ стрима
  const [isTalkVideoActive, setIsTalkVideoActive] = useState(false); // Состояние для активации talk-video
  const isSkipped = useSelector(getIsSkipped);
  const isStartVolume = useSelector(getIsStartVolume);
  const isClicked = useSelector(getIsClicked);
  const effectTriggeredRef = useRef(false);
  useEffect(() => {
    if (startVideoRef.current && isVolume) {
        startVideoRef.current.play();
    }
  }, [isVolume]);

  useEffect(() => {
    if (startVideoRef.current && isSkipped) {
      startVideoRef.current.pause();
      effectTriggeredRef.current = true; // Устанавливаем флаг через useRef
      handleVideoEnd();
    }
  }, [isSkipped]);

// Обработчик окончания воспроизведения стартового видео
  const handleVideoEnd = () => {
    if (bodyVideoRef.current) {
      bodyVideoRef.current.classList.remove('hidden');
      bodyVideoRef.current.play(); // Запускаем основное видео
    }
    if (startVideoRef.current) {
      startVideoRef.current.classList.add('hidden');

      // Проверяем, нужно ли устанавливать задержку
      if (effectTriggeredRef.current) {
        setTimeout(() => {
          dispatch(setIsStreamingLoading(false));
          effectTriggeredRef.current = false; // Сбрасываем флаг после завершения задержки
        }, 6000);
      } else {
        dispatch(setIsStreamingLoading(false)); // Выполняем сразу, если задержка не нужна
      }

      dispatch(setIsSkipped(true));
      dispatch(setMicroButton(true));
      dispatch(setButtonActive(false)); // Скрываем стартовое видео
    }
  };

  // Обработчик окончания стрима (talk-video)
  const handleTalkVideoEnd = () => {
    setIsStreamPlaying(false); // Отключаем флаг стрима
    setIsTalkVideoActive(false); // Деактивируем класс active для стрим видео

    // Возвращаем основное видео
    if (bodyVideoRef.current) {
      bodyVideoRef.current.play();
    }
  };

  useEffect(() => {
    const currentIsReload = store.getState().chat.isReload;
    if (isStreaming && talkVideoRef.current && !currentIsReload) {
      setIsStreamPlaying(true); // Включаем стрим

      // Ожидаем завершения основного видео с задержкой, чтобы плавно перейти к стриму
      setTimeout(() => {
        if (bodyVideoRef.current) {
          bodyVideoRef.current.pause(); // Останавливаем основное видео
        }

        // Включаем видео стрима через 3 секунды
        setIsTalkVideoActive(true);
        if (talkVideoRef.current) {
          talkVideoRef.current.play();
        }
      }, 2400); // Задержка 3 секунды перед стримом
    } else if (!isStreaming && isStreamPlaying) {
      // Если стрим завершен
      setIsStreamPlaying(false);
      setIsTalkVideoActive(false); // Деактивируем talk-video

      // Восстанавливаем воспроизведение основного видео
      if (bodyVideoRef.current) {
        bodyVideoRef.current.play();
      }
    }
  }, [isStreaming]);


  useEffect(() => {
    if (startVideoRef.current) {
      startVideoRef.current.addEventListener('ended', handleVideoEnd);
    }

    if (talkVideoRef.current) {
      talkVideoRef.current.addEventListener('ended', handleTalkVideoEnd); // Обработчик окончания стрима
    }

    return () => {
      // Удаляем обработчики при размонтировании
      if (startVideoRef.current) {
        startVideoRef.current.removeEventListener('ended', handleVideoEnd);
      }
      if (talkVideoRef.current) {
        talkVideoRef.current.removeEventListener('ended', handleTalkVideoEnd);
      }
    };
  }, []);

  return (
    <div
      id="video-wrapper"
      className={!isStartVolume ? `video-wrapper skeleton` : 'video-wrapper'}
    >
      <div className="skeleton" style={isStartVolume ? { display: 'none' } : {}}></div>

      <video
        id="talk-video"
        className={`${isTalkVideoActive ? 'talk-video active' : 'talk-video'} ${!isStartVolume ? 'skeleton' : ''}`}
        ref={talkVideoRef}
        playsInline
      ></video>
      <video
        id="start-video"
        className={`start-video ${!isStartVolume ? 'skeleton' : ''}`}
        ref={startVideoRef}
        src="videos/hello11.mp4"
        playsInline
      ></video>
      <video
        id="video-body"
        className={`video-body ${isStreaming ? 'hidden' : ''} ${!isStartVolume ? 'skeleton' : ''}`}
        ref={bodyVideoRef}
        src="videos/body.mp4"
        autoPlay
        muted
        loop
        playsInline
      ></video>
    </div>
  );
};

export default VideoWrapper;
