import React from 'react';
import { useDispatch } from 'react-redux';
import { setPrivacy } from '../../../store/chat/chat-slice';
import { AppDispatch } from '../../../store/store';
import { Link } from 'react-router-dom';
import styles from './Privacy.module.scss'

const Privacy: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const handlePrivacy = () => {
    dispatch(setPrivacy(false));
  };

  return (
    <div className={styles.privacyContainer}>
      <div className={styles.backAbs} onClick={handlePrivacy}>
        Назад
      </div>

      <div className={styles.privacyContent}>
        <h1 className={styles.h1}>Соглашение об использовании аватара «Артём»</h1>
        <p className={`${styles.bold} ${styles.p}`}>
          Пожалуйста, прочтите текст ниже и кликните на клавишу «Я согласен», чтобы подтвердить, что
          вы прочли и приняли правила ниже, заключив настоящее соглашение об использовании программно-информационного
          продукта аватара «Артём», размещенного на следующем интернет-ресурсе: gipotireoz.ru (далее – Сайт).
        </p>
        <p className={styles.p}>
          Настоящий программно-информационный продукт аватар «Артём» (далее – Аватар) разработан ООО «Мерк» (далее – Разработчик) на основании массивов опубликованных данных по терапии соответствующих заболеваний; клинических рекомендаций; иных официальных документов и/или опубликованных источников.
          Информация, полученная с помощью аватара, приведена исключительно в справочно-информационных целях.
        </p>
        <p className={styles.p}>
          Настоящий Аватар разработан <span className={styles.bold}>ДЛЯ ИСПОЛЬЗОВАНИЯ МЕДИЦИНСКИМИ РАБОТНИКАМИ в целях получения ими изложенной в доступной форме информации о заболеваниях щитовидной железы, а также в целях ознакомления медицинских работников с информацией в области эндокринологии. Аватар НЕ ПРЕДНАЗНАЧЕН ДЛЯ ПОСТАНОВКИ ДИАГНОЗОВ, НАЗНАЧЕНИЯ ЛЕЧЕНИЯ КОНКРЕТНЫМ ПАЦИЕНТАМ, ОПРЕДЕЛЕНИЯ ПОРЯДКА ЗАПОЛНЕНИЯ РЕЦЕПТУРНЫХ БЛАНКОВ</span> Пользователями Аватара могут быть ТОЛЬКО физические лица, имеющие медицинское образование, работающие в медицинской организации, осуществляющей медицинскую деятельность на основании действующей лицензии на территории РФ </p>
        <p className={styles.p}>
          <span className={styles.bold}>ОТВЕТСТВЕННОСТЬ ЗА ЛЮБОЕ ИСПОЛЬЗОВАНИЕ РЕЗУЛЬТАТОВ, полученных при работе с аватаром, в т.ч. при оказании медицинской помощи, НЕСЕТ ПОЛЬЗОВАТЕЛЬ</span>
          аватара. Разработчик аватара, а равно иное лицо, при поддержке которого мог быть создан аватар, не несут перед пользователями и иными третьими лицами ответственности (предел ответственности составляет «0» (ноль) рублей) за работу Аватара и использование информации, полученной с его помощью, в т.ч. за корректность, релевантность и применимость такой полученной информации в каких-либо конкретных ситуациях, возникающих в клинической практике.
        </p>
        <h1 className={styles.h1}>Право использования Аватара <br /><span className={styles.bold}>предоставляется на безвозмездной основе.</span></h1>

        <div className={styles.agreementText}>
          <h3 className={styles.h3}>Принимая настоящие Правила, ВЫ (Пользователь) ЗАВЕРЯЕТЕ, что Вы:</h3>
          <ol className={`${styles.agreementList} ${styles.ol}`}>
            <li className={styles.li}>являетесь медицинским работником;</li>
            <li className={styles.li}>понимаете, что аватар разработан исключительно в справочно-информационных целях и предоставляется в пользование на территории РФ на условиях «как есть» (в т.ч. без каких-либо обязательств со стороны ООО «Мерк» по верификации конкретных полученных с использованием аватара результатов);</li>
            <li className={styles.li}>планируете использовать аватар исключительно в тех целях, для которых он разработан, и указанных выше в настоящем соглашении;</li>
            <li className={styles.li}>понимаете, что ответственность за любое использование результатов, полученных при использовании Вами аватара, лежит на Вас;</li>
            <li className={styles.li}>понимаете, что <span className={styles.bold}>ООО «Мерк» НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА ПОСЛЕДСТВИЯ ЕГО ИСПОЛЬЗОВАНИЯ ВАМИ, а равно за последствия использование любой информации, полученной с использованием аватара. ООО «Мерк» не гарантирует корректной и бесперебойной работы Аватара</span> (включая доступность ссылок, размещенных в аватаре), актуальности информации на день обращения к аватару, совместимости аватара с установленным на Вашем ПК программным обеспечением (в т.ч. браузерами), а равно соответствия иным Вашим ожиданиям от использования аватара;</li>
            <li className={styles.li}>обязуетесь не использовать аватар для осуществления какой-либо запрещенной законом деятельности;</li>
            <li className={styles.li}>обязуетесь не загружать в аватар какие-либо данные, которые могут быть квалифицированы как персональные данные и/или сведения, составляющие медицинскую или иную охраняемую законом информацию;</li>
          </ol>
        </div>

        <button className={styles.back} onClick={handlePrivacy}>
          Назад
        </button>
      </div>
    </div>
  );
};

export default Privacy;
