import React, { useState } from 'react';
import VideoWrapper from 'components/widgets/VideoWrapper/VideoWrapper';
import ChatComponent from './components/widgets/Chat/ChatComponent';
import AllowVolume from 'components/shared/AllowVolume/AllowVolume';

const App: React.FC = () => {
  const [isOverlayVisible, setOverlayVisible] = useState(true);

  return (
    <>
      {isOverlayVisible && <div className="overlay" id="overlay"></div>}
      <div id="content" className="content-container">
        <VideoWrapper />
        <>
          <ChatComponent />
        </>
        <AllowVolume setOverlayVisible={setOverlayVisible} />
      </div>
    </>
  );
};

export default App;
