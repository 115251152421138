import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import RecordRTC from 'recordrtc';
import { useDispatch, useSelector } from 'react-redux';
import store, { AppDispatch, RootState } from '../../../store/store';
import { getStream } from '../../../utils/didApi';
import { resetInterrupted, setButtonActive } from '../../../store/stream/stream-slice';
import { stopStreaming } from '../../../store/stream/stream-operations';
import { marked } from 'marked';
import {
  getChatMessages,
  getIsThinking,
  getThreadId,
  getTypingMessage,
  getIsStarterQuestion,
  getMute,
  getIsRecording,
  getIsStreamingLoading,
  getIsStartHelp,
  getSendButton,
  getMicroButton,
  getIsReload,
} from '../../../store/chat/chat-selectors';
import { getIsStreaming } from '../../../store/stream/stream-selectors';
import {
  setMessage,
  addMessageToChat,
  setIsThinking,
  setThreadId,
  setTypingMessage,
  setIsStarterQuestion,
  setMute,
  setIsRecording,
  setIsInputDisabled,
  setIsStreaming,
  setIsStreamingLoading,
  setIsStartHelp,
  setSendButton,
  setMicroButton,
  clearChatHistory,
  setIsReload,
} from '../../../store/chat/chat-slice';
import SvgIcon from '../../shared/icons/SvgIcon';

export interface ChatComponentHandle {
  restart: () => void;
}

const ChatComponent = forwardRef<ChatComponentHandle>((props, ref) => {
  const dispatch: AppDispatch = useDispatch();
  const message = useSelector((state: RootState) => state.chat.message);
  const messages = useSelector(getChatMessages);
  const isThinking = useSelector(getIsThinking);
  const threadId = useSelector(getThreadId);
  const typingMessage = useSelector(getTypingMessage);
  const isStarterQuestion = useSelector(getIsStarterQuestion);
  const mute = useSelector(getMute);
  const isRecording = useSelector(getIsRecording);
  const isStreaming = useSelector(getIsStreaming);
  const isStreamingLoading = useSelector(getIsStreamingLoading);
  const isStartHelp = useSelector(getIsStartHelp);
  const sendButton = useSelector(getSendButton);
  const microButton = useSelector(getMicroButton);
  const isReload = useSelector(getIsReload);
  const [recorder, setRecorder] = useState<RecordRTC | null>(null); // Добавляем состояние для recorder
  const getState = useSelector((state: RootState) => state);
  const [charLimit] = useState(200);
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlRec = process.env.REACT_APP_API_URL_REC;
  const isButtonActive = useSelector((state: RootState) => state.stream.isButtonActive);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedReactions, setSelectedReactions] = useState<{ [key: string]: string }>({});
  const isInterruptedRef = useRef(false);
  const [isChatMobShow, setIsChatMobShow] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Проверка, меньше ли ширина 1024px
    };

    handleResize(); // Вызвать функцию один раз при монтировании
    window.addEventListener('resize', handleResize); // Добавить обработчик события изменения размера

    return () => {
      window.removeEventListener('resize', handleResize); // Удалить обработчик при размонтировании
    };
  }, []);

  const starterQuestions = [
    'Что ты умеешь?',
    'Откуда берешь данные?',
    'Как я могу тебя использовать?',
  ];

  const handleSendMessage = async (userMessage: string) => {
    if (!userMessage || userMessage.length > charLimit) return;
    dispatch(setIsReload(false));
    // Сбросим флаг рестарта при новом запросе
    console.log('Sending message:', userMessage);

    dispatch(setMicroButton(false));
    dispatch(setIsStreamingLoading(true));
    dispatch(addMessageToChat({ user: true, content: userMessage, id: 'null' }));
    dispatch(setIsStartHelp(false));
    dispatch(setIsThinking(true));
    dispatch(setMessage(''));
    dispatch(setTypingMessage(''));
    dispatch(setButtonActive(true));

    dispatch(resetInterrupted());

    try {
      const response = await sendMessageToApi(userMessage);
      console.log('Received response:', response);
      const currentIsReload = store.getState().chat.isReload;
      // Проверка перед обработкой ответа
      if (!currentIsReload) {
        await handleResponses(response);
      } else {
        console.log('Restarted flag is set. Skipping response handling.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      dispatch(setIsThinking(false));
      dispatch(setButtonActive(false));
      dispatch(setSendButton(false));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleResponses = async (responseData: any) => {
    const messages = responseData.messages;
    console.log('Handling responses:', messages);
    const currentIsReload = store.getState().chat.isReload;

    // Проверяем флаг перезагрузки перед началом обработки
    if (currentIsReload) {
      console.log('Restarted flag is set. Exiting response handling.');
      return;
    }

    for (let i = 0; i < messages.length; i++) {
      const currentIsReload = store.getState().chat.isReload;
      if (isInterruptedRef.current || currentIsReload) {
        console.log('Response handling interrupted or restarted. Exiting loop.');
        return; // Проверяем флаг перезагрузки
      }

      const message = messages[i];
      console.log('Processing message:', message);

      if (message.role === 'SYSTEM' && message.content) {
        dispatch(setIsThinking(true));

        const streamingPromise = message.audio_url
          ? getStream(message.audio_url, dispatch, store.getState) // Используйте store.getState
          : Promise.resolve();

        if (message.audio_url) {
          await streamingPromise;
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
        if (isInterruptedRef.current || currentIsReload) {
          console.log(
            'Response handling interrupted or restarted during delay. Clearing chat history.',
          );
          dispatch(clearChatHistory());
        }

        dispatch(setIsThinking(false));

        // Устанавливаем isStreaming в true перед началом печати
        dispatch(setIsStreaming(true));
        console.log('isStreaming set to true');

        if (isInterruptedRef.current || currentIsReload) {
          console.log('Response handling interrupted or restarted. Exiting.');
          return; // Проверяем флаг перезагрузки
        }

        // Начинаем симуляцию печати
        const typingPromise = simulateTypingEffect(message.source_url, message.content, message.id);
        await typingPromise;

        if (isInterruptedRef.current || currentIsReload) {
          console.log('Response handling interrupted or restarted during typing effect. Exiting.');
          return; // Проверяем флаг перезагрузки
        }

        // После завершения печати, если нужно, устанавливаем isStreaming в false
        dispatch(setButtonActive(false));
        dispatch(setSendButton(false));
        dispatch(setMicroButton(true));
        console.log('isStreaming set to false after typing');
      }
    }
  };

  const sendMessageToApi = async (userMessage: string) => {
    dispatch(setIsStarterQuestion(false));
    isInterruptedRef.current = false;
    const url = threadId ? `${apiUrl}/add_message` : `${apiUrl}/start_message`;
    const payload = threadId
      ? { user_message: userMessage, thread_id: threadId }
      : { user_message: userMessage };
    dispatch(resetInterrupted());
    const response = await axios.post(url, payload);

    if (!threadId && response.data.thread_id) {
      dispatch(setThreadId(response.data.thread_id));
    }

    return response.data;
  };

  const simulateTypingEffect = async (sourceUrls: string[], responseText: string, id: string) => {
    console.log('isRestarted:', isReload);
    const currentIsReload = store.getState().chat.isReload;
    if (isInterruptedRef.current || currentIsReload) return;

    let formattedMessage = '';
    let isInterruptedOccured = false;

    for (let i = 0; i < responseText.length; i++) {
      if (isInterruptedRef.current) {
        if (!isInterruptedOccured) {
          dispatch(addMessageToChat({ user: false, content: formattedMessage, id }));
          dispatch(setTypingMessage(''));
          isInterruptedOccured = true;
        }
        return;
      }

      await new Promise(resolve => setTimeout(resolve, 59));

      formattedMessage += responseText[i];
      dispatch(setTypingMessage(formattedMessage)); // Отображаем текст без парсинга пока идёт печать

      if (i === responseText.length - 1 && !isInterruptedOccured) {
        // Добавляем ссылки в конце сообщения, если они есть
        let finalMessageWithLinks = formattedMessage;

        if (sourceUrls && sourceUrls.length > 0) {
          let sourcesHtml = '<br/><br/>Источники:<ul>';
          sourceUrls.forEach(url => {
            sourcesHtml += `<li><a href="${url}" target="_blank">${url}</a></li>`;
          });
          sourcesHtml += '</ul>';

          // Важно: используем innerHTML для корректного рендеринга HTML
          finalMessageWithLinks += sourcesHtml;
        }

        // Добавляем финальное сообщение с разметкой и ссылками в HTML
        dispatch(addMessageToChat({ user: false, content: finalMessageWithLinks, id }));
        dispatch(setTypingMessage(''));
      }
    }

    dispatch(setIsStreamingLoading(false));
  };

  const handleStop = () => {
    dispatch(setMicroButton(false));
    dispatch(setIsThinking(false));
    dispatch(setTypingMessage(''));
    isInterruptedRef.current = true;
    dispatch(setIsStreamingLoading(true));
    dispatch(stopStreaming());
    dispatch(setIsStreaming(false));
    dispatch(setButtonActive(false));

    dispatch(setSendButton(false));

    setTimeout(() => {
      isInterruptedRef.current = false;
      dispatch(resetInterrupted());
    }, 3000);
    setTimeout(() => {
      dispatch(setIsStreamingLoading(false));
      dispatch(setMicroButton(true));
    }, 10000);
  };

  const handleStarterQuestionClick = (question: string) => {
    dispatch(setIsStarterQuestion(false));
    handleSendMessage(question);
  };

  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const [isAutoScroll, setIsAutoScroll] = useState(true);

  const scrollToBottom = () => {
    if (chatHistoryRef.current && isAutoScroll) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  const handleScroll = () => {
    if (chatHistoryRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatHistoryRef.current;
      if (scrollHeight - scrollTop === clientHeight) {
        setIsAutoScroll(true);
      } else {
        setIsAutoScroll(false);
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSendMessage(message);
  };

  const handleMute = () => {
    dispatch(setMute(!mute));
  };

  useEffect(() => {
    const videos = document.querySelectorAll('video');
    videos.forEach(video => {
      if (mute !== null) {
        video.muted = mute;
      }
    });
  }, [mute]);

  const handleRecord = async () => {
    if (isRecording) {
      if (recorder) {
        recorder.stopRecording(async () => {
          const audioBlob = recorder.getBlob();
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64Audio = reader.result?.toString().split(',')[1];
            try {
              const response = await axios.post(apiUrlRec || '', {
                audio: base64Audio,
              });
              const text = response.data.text;

              if (text && text.trim() !== '') {
                dispatch(setMessage(text));
                dispatch(setSendButton(true));
                dispatch(setMicroButton(false));
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              }
              dispatch(setIsInputDisabled(false));
            } catch (error) {
              console.error('Ошибка при отправке аудио:', error);
            }
          };
          reader.readAsDataURL(audioBlob);
        });
        dispatch(setIsRecording(false));
      }
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const newRecorder = new RecordRTC(stream, { type: 'audio' });
        newRecorder.startRecording();
        setRecorder(newRecorder);
        dispatch(setIsRecording(true));
        dispatch(setIsInputDisabled(true));
      } catch (error) {
        console.error('Ошибка при получении доступа к микрофону:', error);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    restart() {
      handleRestart();
    },
  }));

  const handleRestart = () => {
    console.log('Restarting chat...');

    // Устанавливаем флаг перезагрузки сразу
    dispatch(setIsReload(true));
    handleStop(); // Останавливаем печатание
    dispatch(clearChatHistory()); // Очищаем историю сообщений
    dispatch(setMessage('')); // Сбрасываем текущее сообщение
    dispatch(setThreadId(null)); // Сбрасываем идентификатор потока
    dispatch(setIsStarterQuestion(true)); // Возвращаем стартовые вопросы
    dispatch(setIsInputDisabled(false)); // Разблокируем инпут
    dispatch(setIsStreaming(false)); // Устанавливаем состояние "не стримит"
    dispatch(setIsStartHelp(true)); // Возвращаем помощь на стартовое состояние
    dispatch(setButtonActive(false)); // Деактивируем кнопку

    // Убедимся, что очистка истории сообщений произойдет с учетом флага
    setTimeout(() => {
      const currentIsReload = store.getState().chat.isReload; // Получаем актуальное состояние
      console.log('isReload (inside timeout):', currentIsReload);
      // Здесь используем флаг для выполнения логики
      if (currentIsReload) {
        console.log('Clearing chat history after delay...');
        dispatch(clearChatHistory());
      }
    }, 100);
  };

  // useEffect(() => {
  //   dispatch(setIsReload(true));
  //   console.log("вызвано")
  // }, [isInterruptedRef.current]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMessage(e.target.value));

    if (e.target.value.length > 0) {
      dispatch(setSendButton(true));
      dispatch(setMicroButton(false));
    } else {
      dispatch(setSendButton(false));
      dispatch(setMicroButton(true));
    }
  };

  const handleCancelRecording = () => {
    if (recorder) {
      recorder.reset();
      setRecorder(null);
      dispatch(setIsRecording(false));
      dispatch(setIsInputDisabled(false));
    }
  };

  const handleShow = () => {
    setIsChatMobShow(!isChatMobShow); // Инвертируем значение состояния
  };

  const handleReactionClick = async (messageId: string | undefined, reaction: string) => {
    if (!messageId) return; // Проверяем, чтобы messageId не был undefined

    setSelectedReactions(prevReactions => ({
      ...prevReactions,
      [messageId]: reaction,
    }));

    // Определяем реакцию как true или false
    const isPositiveReaction = reaction === '👍';

    try {
      // Отправляем запрос на сервер
      const response = await axios.post(`${apiUrl}/add_reaction`, {
        message_id: messageId, // Отправляем правильный messageId
        reaction: isPositiveReaction, // true для "👍" и false для "👎"
      });

      console.log('Reaction sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending reaction:', error);
    }
  };

  return (
    <div
      className={`chat-container ${isMobile ? 'mobile' : ''} ${isChatMobShow ? 'show' : ''}`}
      id="chatContainer"
    >
      <div className={isMobile ? 'menuTop mob' : 'menuTop'}>
        <div className="muteContainer" onClick={handleMute}>
          <div className={mute ? 'muteBack active' : 'muteBack'} id="muteContainer">
            <div id="mute" className={mute ? 'mute active' : 'mute'}></div>
          </div>
        </div>
        {messages.length > 0 && (
          <div className="muteContainer" onClick={handleRestart}>
            <div className="muteBack" id="restartContainer">
              <div id="restart" className="restart"></div>
            </div>
          </div>
        )}
      </div>

      <div
        id="msgHistory"
        className={`chat-container__history ${isMobile ? 'mob' : ''} ${
          isChatMobShow ? 'show' : ''
        }`}
        ref={chatHistoryRef}
        onScroll={handleScroll}
      >
        <div className="chat-content">
          {/* Стартовые вопросы */}
          {isStartHelp && (
            <div className="message system">
              Здравствуйте, Я - Артем. Напишите ваш вопрос или выберите ниже👇
            </div>
          )}

          <div
            id="starter-questions"
            className={isStarterQuestion ? 'starter-questions' : 'starter-questions active'}
          >
            {starterQuestions.map((question, index) => (
              <button
                key={index}
                className="starter-question"
                onClick={() => handleStarterQuestionClick(question)}
                disabled={isButtonActive}
              >
                {question}
                <div className="starter-questionArrow"></div>
              </button>
            ))}
          </div>
          {messages.map(msg => {
            if (!msg.id) return null; // Проверяем наличие msg.id перед использованием
            return (
              <div key={msg.id} className={`message ${msg.user ? 'user' : 'system'}`}>
                <div className="messageContainer">
                  <div className='messageText' dangerouslySetInnerHTML={{ __html: msg.content }} />
                  {!msg.user &&
                    (selectedReactions[msg.id] ? ( // Теперь msg.id можно безопасно использовать в качестве индекса
                      <div className="selected-reaction">{selectedReactions[msg.id]}</div>
                    ) : (
                      <div className="reactionContainer">
                        <button
                          className="like"
                          onClick={() => handleReactionClick(msg.id || '', '👍')} // Передаем msg.id как идентификатор
                        >
                          <span>👍</span>
                        </button>
                        <button
                          className="like"
                          onClick={() => handleReactionClick(msg.id || '', '👎')} // Передаем msg.id как идентификатор
                        >
                          <span>👎</span>
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
          {isThinking && (
            <div className="message system">
              Думаю<span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          )}
          {typingMessage && (
            <div
              className="message system"
              dangerouslySetInnerHTML={{ __html: typingMessage }} // Отображаем HTML
            ></div>
          )}
        </div>
      </div>

      <form
        id="chat-form"
        className={
          isButtonActive
            ? 'chat-container__input hidden'
            : sendButton
            ? 'chat-container__input active'
            : 'chat-container__input'
        }
        onSubmit={handleFormSubmit}
      >
        {isMobile && (
          <div className="menuMobile">
            <button type="button" onClick={handleShow}>
              {isChatMobShow ? 'Показать стрим' : 'Показать чат'}
            </button>
          </div>
        )}
        <input
          ref={inputRef}
          id="textArea"
          className={
            !isButtonActive ? 'chat-container__textarea' : 'chat-container__textarea hidden'
          }
          placeholder="Напишите сообщение"
          value={message}
          onChange={handleInputChange}
          disabled={
            isButtonActive || isRecording || isThinking || isStreaming || isStreamingLoading
          } // Disable input while waiting for response
        />
        <div className="inputButton">
          {microButton && (
            <>
              {isRecording ? (
                <div className="recornbtns">
                  <button
                    onClick={handleCancelRecording}
                    id="recognize-button"
                    className="chat-container__send-button"
                    aria-label="Recognize Speech"
                    type="button"
                    disabled={isButtonActive}
                  >
                    <div id="stopMicro" className="stopMicro"></div>
                  </button>
                  <button
                    onClick={handleRecord}
                    id="recognize-submit"
                    className="chat-container__send-button"
                    aria-label="Recognize Speech"
                    type="button"
                    disabled={isButtonActive}
                  >
                    <div className="submitContainer">
                      <div id="submitRecord" className="submitRecord"></div>
                    </div>
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleRecord}
                  id="recognize-button"
                  className={
                    !isButtonActive
                      ? 'chat-container__send-button'
                      : 'chat-container__send-button hidden'
                  }
                  aria-label="Recognize Speech"
                  type="button"
                  disabled={isButtonActive}
                >
                  <div className="submitContainer">
                    <div id="circleMicrophone" className="circleMicrophone"></div>
                  </div>
                </button>
              )}
            </>
          )}

          {isStreamingLoading ? (
            isStreaming ? (
              <button
                onClick={handleStop}
                type="button"
                disabled={!isStreaming}
                id="stop-button-container"
                className="stop-button-container"
              >
                <button id="stop-button" className="stop-button"></button>
              </button>
            ) : (
              <button
                onClick={handleStop}
                type="button"
                disabled={!isStreaming}
                id="stop-button-container loading"
                className="stop-button-container loading"
              >
                <button id="stop-button" className="stop-button loading"></button>
              </button>
            )
          ) : (
            sendButton && (
              <button
                id="send-button"
                className="chat-container__send-button active"
                onClick={() => handleSendMessage(message)}
                type="button"
                disabled={isThinking || isButtonActive} // Disable button while thinking
              >
                <SvgIcon className={'Layer_1'} name={'Layer_1'} />
              </button>
            )
          )}
        </div>
      </form>
    </div>
  );
});

export default ChatComponent;
