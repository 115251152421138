import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/assets/style.scss';
import App from './App';

// Импортируем Provider из react-redux
import { Provider } from 'react-redux';
// Импортируем созданное хранилище Redux
import store from './store/store';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Privacy from './components/docs/privacy/privacy';
import Agree from './components/docs/agree/agree';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);



root.render(
  <React.StrictMode>
    <Router>
      {/* Оборачиваем App в Provider и передаём store */}
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/privacy" element={<Privacy  />} />
          <Route path="/agree" element={<Agree  />} />
        </Routes>
      </Provider>
    </Router>
  </React.StrictMode>
);
