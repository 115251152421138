// streamSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StreamState } from './StreamTypes';

// Начальное состояние
const initialState: StreamState = {
  isStreaming: false,
  peerConnection: null,
  streamId: null,
  sessionId: null,
  isButtonActive: true,
  isStartVolume: false,
  isInterrupted: false,
};

// Создание слайса
const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    setButtonActive(state, action: PayloadAction<boolean>) {
      state.isButtonActive = action.payload;
    },
    startVolume(state, action: PayloadAction<boolean>) {
      state.isStartVolume = action.payload;
    },
    setInterrupted: (state, action: PayloadAction<boolean>) => {
      state.isInterrupted = action.payload;
    },
    resetInterrupted(state) {
      state.isInterrupted = false;
    },
    setPeerConnection(state, action: PayloadAction<RTCPeerConnection | null>) {
      state.peerConnection = action.payload;
    },
    setStreamId(state, action: PayloadAction<string | null>) {
      state.streamId = action.payload;
    },
    setSessionId(state, action: PayloadAction<string | null>) {
      state.sessionId = action.payload;
    },
    setStreaming(state, action: PayloadAction<boolean>) {
      state.isStreaming = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase('stream/startStreaming/fulfilled', state => {
        state.isStreaming = true;
      })
      .addCase('stream/startStreaming/rejected', state => {
        state.isStreaming = false;
      })
      .addCase('stream/stopStreaming/fulfilled', state => {
        state.isStreaming = false;
        state.peerConnection = null;
      })
      .addCase('stream/stopStreaming/rejected', state => {
        state.isStreaming = false;
      });
  },
});

// Экспортируем действия
export const {
  setButtonActive,
  startVolume,
  setInterrupted,
  resetInterrupted,
  setPeerConnection,
  setStreamId,
  setSessionId,
  setStreaming,
} = streamSlice.actions;

// Экспортируем редюсер
export default streamSlice.reducer;
