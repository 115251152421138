// streamOperations.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConnection, getStream } from '../../utils/didApi';
import { RootState, AppDispatch } from '../store';
import {
  resetInterrupted,
  setInterrupted,
  setPeerConnection,
  setSessionId,
  setStreamId,
  setStreaming,
} from './stream-slice';

// Thunk to establish connection
export const establishConnection = createAsyncThunk<void, void, { state: RootState; dispatch: AppDispatch }>(
  'stream/establishConnection',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const { peerConnection } = state.stream;

    // Close existing peer connection if it exists
    if (peerConnection) {
      peerConnection.close();
      dispatch(setPeerConnection(null));
    }

    // Reset stream and session IDs
    dispatch(setStreamId(null));
    dispatch(setSessionId(null));

    // Create new connection
    await getConnection(dispatch);
  }
);

// Thunk for starting the stream
export const startStreaming = createAsyncThunk<void, string, { state: RootState; dispatch: AppDispatch }>(
  'stream/startStreaming',
  async (audioUrl, { dispatch, getState }) => {
    const state = getState();
    const { peerConnection, isInterrupted } = state.stream;

    // Reset interruption state if necessary
    if (isInterrupted) {
      dispatch(resetInterrupted());
    }

    // Close existing peer connection
    if (peerConnection) {
      peerConnection.close();
      dispatch(setPeerConnection(null));
    }

    // Establish connection first
    await dispatch(establishConnection());

    const { streamId, sessionId } = getState().stream;

    // Start streaming if connection is established
    if (streamId && sessionId) {
      await getStream(audioUrl, dispatch, getState);
      dispatch(setStreaming(true));
    } else {
      console.error('Failed to start stream: Missing stream or session ID.');
    }
  }
);

// Thunk to stop streaming
export const stopStreaming = createAsyncThunk<void, void, { state: RootState; dispatch: AppDispatch }>(
  'stream/stopStreaming',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const { peerConnection } = state.stream;

    // Close existing peer connection
    if (peerConnection) {
      peerConnection.close();
      dispatch(setPeerConnection(null));
    }

    // Set interrupted state and stop streaming
    dispatch(setInterrupted(true));
    dispatch(setStreaming(false));

    // Optionally create a new connection after interruption
    await dispatch(establishConnection());
  }
);
