import { AppDispatch, RootState } from '../store/store';
import {
  setPeerConnection,
  setStreamId,
  setSessionId,
  setStreaming,
  resetInterrupted,
} from '../store/stream/stream-slice';
import { establishConnection, stopStreaming } from 'store/stream/stream-operations';

const DID_API = {
  key: process.env.REACT_APP_API_KEY,
  url: process.env.REACT_APP_DID_API_URL,
  source_url: process.env.REACT_APP_DID_API_SOURCE_URL,
};

let peerConnection: RTCPeerConnection | null = null;

export const getStream = async (url: string, dispatch: AppDispatch, getState: () => RootState) => {
  let attempt = 0;
  const maxAttempts = 3;
  let success = false;

  try {
    const state = getState();
    let { streamId, sessionId, isInterrupted } = state.stream;

    // Принудительный сброс isInterrupted перед повторной попыткой
    dispatch(resetInterrupted());

    // Выполняем запрос на запуск стрима с повторной попыткой при ошибке
    while (attempt < maxAttempts && !success) {
      attempt++;

      const currentState = getState();
      isInterrupted = currentState.stream.isInterrupted;

      if (isInterrupted) {
        return;
      }

      try {
        const talkResponse = await fetch(`${DID_API.url}/talks/streams/${streamId}`, {
          method: 'POST',
          headers: {
            Authorization: `Basic ${DID_API.key}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            script: {
              type: 'audio',
              audio_url: url,
              subtitles: 'false',
              ssml: true,
            },
            config: {
              stitch: true,
            },
            driver_url: 'bank://lively/',
            session_id: sessionId,
          }),
        });

        if (talkResponse.ok) {
          success = true;
        } else {

          // Восстанавливаем соединение
          await dispatch(establishConnection());

          if (isInterrupted) {
            return;
          }

          // Ждем 3 секунды перед запуском стрима
          await new Promise(resolve => setTimeout(resolve, 3000));

          if (attempt >= maxAttempts) {
            throw new Error('Failed to connect after multiple attempts.');
          }
        }
      } catch (error) {
        if (attempt >= maxAttempts) {
          throw error;
        }
      }
    }

    // Если стрим был успешным, продолжаем обработку
    if (success) {
      dispatch(setStreaming(true));

      const audio = new Audio(url);
      audio.addEventListener('loadedmetadata', () => {
        const duration = audio.duration;
        const timeoutDuration = (duration + 2.5) * 1000;

        setTimeout(() => {
          dispatch(setStreaming(false));
        }, timeoutDuration);
      });
    }
  } catch (error) {
    dispatch(stopStreaming()); // Если исчерпаны все попытки, выходим
  }
};

export const destroyConnecton = async (dispatch: AppDispatch) => {
  let retryCount = 0;
  const maxRetries = 1; // Количество попыток (попробуем еще один раз)

  while (retryCount <= maxRetries) {
    try {
      // Очищаем видеоэлемент перед новым соединением

      // Закрываем текущее соединение
      if (peerConnection) {
        peerConnection.getSenders().forEach(sender => {
          sender.track?.stop(); // Останавливаем все треки
        });
        peerConnection.close();
        dispatch(setPeerConnection(null)); // Очищаем peerConnection в состоянии
      }

      // Сбрасываем состояния
      dispatch(setStreamId(null));
      dispatch(setSessionId(null));
      dispatch(setStreaming(false));
      dispatch(resetInterrupted());

      // Запрос на установление нового соединения
      const sessionResponse = await fetch(`${DID_API.url}/talks/streams`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source_url: DID_API.source_url,
          output_resolution: 1080,
        }),
      });

      // Логируем ответ от API
      const data = await sessionResponse.json();
      if (!data.id || !data.session_id) {
        throw new Error('Stream ID or Session ID отсутствуют в ответе сервера.');
      }

      const { id: streamId, offer, ice_servers: iceServers, session_id: sessionId } = data;

      // Создаем новое PeerConnection
      peerConnection = new RTCPeerConnection({ iceServers });
      dispatch(setPeerConnection(peerConnection));
      dispatch(setStreamId(streamId));
      dispatch(setSessionId(sessionId));

      // Добавляем обработчики для нового соединения
      peerConnection.addEventListener('icecandidate', event =>
        onIceCandidate(event, streamId, sessionId),
      );

      peerConnection.addEventListener('iceconnectionstatechange', () =>
        onIceConnectionStateChange(dispatch),
      );

      // Обработчик track для привязки потока
      peerConnection.addEventListener('track', event => {
        onTrack(event, dispatch);
      });

      // Устанавливаем удаленное описание
      await peerConnection.setRemoteDescription(offer);
      const sessionClientAnswer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(sessionClientAnswer);

      // Отправляем SDP-сообщение серверу
      await fetch(`${DID_API.url}/talks/streams/${streamId}/sdp`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answer: sessionClientAnswer,
          session_id: sessionId,
        }),
      });

      // Если успех, выходим из цикла
      break;
    } catch (error) {

      // Проверяем, достигнуто ли максимальное количество попыток
      if (retryCount >= maxRetries) {
        dispatch(stopStreaming()); // Останавливаем стрим при окончательной ошибке
        break; // Завершаем цикл, если достигнуто максимальное количество попыток
      }

      retryCount++; // Увеличиваем счетчик попыток
    }
  }
};


export const getConnection = async (dispatch: AppDispatch) => {
  let retryCount = 0;
  const maxRetries = 1; // Количество попыток (попробуем еще один раз)

  while (retryCount <= maxRetries) {
    try {
      // Очищаем видеоэлемент перед новым соединением

      // Закрываем текущее соединение
      if (peerConnection) {
        peerConnection.getSenders().forEach(sender => {
          sender.track?.stop(); // Останавливаем все треки
        });
        peerConnection.close();
        dispatch(setPeerConnection(null)); // Очищаем peerConnection в состоянии
      }

      // Сбрасываем состояния
      dispatch(setStreamId(null));
      dispatch(setSessionId(null));
      dispatch(setStreaming(false));
      dispatch(resetInterrupted());

      // Запрос на установление нового соединения
      const sessionResponse = await fetch(`${DID_API.url}/talks/streams`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source_url: DID_API.source_url,
          output_resolution: 1080,
        }),
      });

      // Логируем ответ от API
      const data = await sessionResponse.json();
      if (!data.id || !data.session_id) {
        throw new Error('Stream ID or Session ID отсутствуют в ответе сервера.');
      }

      const { id: streamId, offer, ice_servers: iceServers, session_id: sessionId } = data;

      // Создаем новое PeerConnection
      peerConnection = new RTCPeerConnection({ iceServers });
      dispatch(setPeerConnection(peerConnection));
      dispatch(setStreamId(streamId));
      dispatch(setSessionId(sessionId));

      // Добавляем обработчики для нового соединения
      peerConnection.addEventListener('icecandidate', event =>
        onIceCandidate(event, streamId, sessionId),
      );

      peerConnection.addEventListener('iceconnectionstatechange', () =>
        onIceConnectionStateChange(dispatch),
      );

      // Обработчик track для привязки потока
      peerConnection.addEventListener('track', event => {
        onTrack(event, dispatch);
      });

      // Устанавливаем удаленное описание
      await peerConnection.setRemoteDescription(offer);
      const sessionClientAnswer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(sessionClientAnswer);

      // Отправляем SDP-сообщение серверу
      await fetch(`${DID_API.url}/talks/streams/${streamId}/sdp`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          answer: sessionClientAnswer,
          session_id: sessionId,
        }),
      });

      // Если успех, выходим из цикла
      break;
    } catch (error) {

      // Проверяем, достигнуто ли максимальное количество попыток
      if (retryCount >= maxRetries) {
        dispatch(stopStreaming()); // Останавливаем стрим при окончательной ошибке
        break; // Завершаем цикл, если достигнуто максимальное количество попыток
      }

      retryCount++; // Увеличиваем счетчик попыток
    }
  }
};

// Обработчики событий
const onIceCandidate = async (
  event: RTCPeerConnectionIceEvent,
  streamId: string,
  sessionId: string,
) => {
  if (event.candidate) {
    const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

    await fetch(`${DID_API.url}/talks/streams/${streamId}/ice`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${DID_API.key}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        candidate,
        sdpMid,
        sdpMLineIndex,
        session_id: sessionId,
      }),
    });
  }
};

const onIceConnectionStateChange = (dispatch: AppDispatch) => {
  if (peerConnection) {
    if (['failed', 'closed', 'disconnected'].includes(peerConnection.iceConnectionState)) {
      closePC(); // Закрываем соединение
      dispatch(stopStreaming()); // Останавливаем стрим
    }
  }
};

const onTrack = (event: RTCTrackEvent, dispatch: AppDispatch) => {
  const remoteStream = event.streams[0];
  const videoElement = document.getElementById('talk-video') as HTMLVideoElement;

  if (!remoteStream) {
    return;
  }

  // Проверяем наличие треков
  const videoTrack = remoteStream.getVideoTracks()[0];
  if (!videoTrack) {
    return;
  }

  if (videoElement) {
    videoElement.srcObject = remoteStream; // Привязываем поток к видеоэлементу
  }

  // Отслеживаем завершение всех треков в потоке
  remoteStream.getTracks().forEach(track => {
    track.addEventListener('ended', () => {
      dispatch(setStreaming(false)); // Стрим завершился, обновляем состояние
      closePC(); // Закрываем соединение
    });
  });

  // Добавляем обработчик завершения потока через MediaStream
  remoteStream.addEventListener('removetrack', () => {
    dispatch(setStreaming(false)); // Стрим завершился, обновляем состояние
    closePC(); // Закрываем соединение
  });
};

export const closePC = () => {
  if (peerConnection) {
    peerConnection.close();
    peerConnection = null;
  }
};
