import React, { useEffect, useState } from 'react';
import VideoWrapper from 'components/widgets/VideoWrapper/VideoWrapper';
import ChatComponent from './components/widgets/Chat/ChatComponent';
import AllowVolume from 'components/shared/AllowVolume/AllowVolume';
import { useSelector } from 'react-redux';
import { getAgree, getAgreeComp, getPrivacy } from './store/chat/chat-selectors';
import Privacy from './components/docs/privacy/privacy';
import Agree from './components/docs/agree/agree';
import { getIsOverlayVisible } from './store/stream/stream-selectors';

const App: React.FC = () => {
  const agree = useSelector(getAgree);
  const agreeComp = useSelector(getAgreeComp);
  const isOverlayVisible = useSelector(getIsOverlayVisible);
  const isPrivacy = useSelector(getPrivacy)

  // Добавляем проверку для отображения Privacy
  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 600) {
        // Если высота окна меньше 600px, значит клавиатура открыта
        document.body.style.overflow = 'hidden'; // Блокируем прокрутку
      } else {
        document.body.style.overflow = ''; // Разблокируем прокрутку
      }
    };

    // Слушаем изменение размера окна
    window.addEventListener('resize', handleResize);

    // Очистка обработчика при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const updateHeight = () => {
      // Проверяем, если ширина окна меньше 1024px
      if (window.innerWidth < 1024) {
        const windowHeight = window.innerHeight;
        const newHeight = windowHeight - 150; // Оставляем пространство для других элементов
        const chatContainer = document.querySelector('.ChatComponent_chatContainerHistory__0yDcD');

        if (chatContainer) {
          chatContainer.setAttribute('style', `height: ${newHeight}px`);
        }
      }
    };

    updateHeight(); // Устанавливаем высоту при загрузке

    // Обновляем высоту при изменении размера окна
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
  return (
    <>

      <>
        {!isPrivacy && isOverlayVisible && <div className="overlay" id="overlay"></div>}
        <div style={isPrivacy ? { display: 'none' } : {}} id="content" className="content-container">
          <VideoWrapper />
          <ChatComponent />
          {!agree && <AllowVolume />}
        </div>
        <div style={!isPrivacy ? { display: 'none', } : {overflowY: "scroll"}}><Privacy /></div>
      </>
    </>
  );
};

export default App;
