// chat-slice.tsx
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatState } from './ChatTypes';

const initialState: ChatState = {
  message: '',
  messages: [],
  isThinking: false,
  threadId: null,
  typingMessage: '',
  isStarterQuestion: true,
  mute: false,
  isRecording: false,
  isInputDisabled: false,
  isStreaming: false,
  isStreamingLoading: true,
  isStartHelp: true,
  sendButton: false,
  microButton: false,
  isReload: false,
  isSkipped: false,
  recordFailed: false,
  recordFade: false,
  agree: false,
  privacy: false,
  agreeComp: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    addMessageToChat(state, action: PayloadAction<{ user: boolean; content: string; id: string }>) {
      state.messages.push(action.payload);
    },
    setIsThinking(state, action: PayloadAction<boolean>) {
      state.isThinking = action.payload;
    },
    setThreadId(state, action: PayloadAction<string | null>) {
      state.threadId = action.payload;
    },
    setTypingMessage(state, action: PayloadAction<string>) {
      state.typingMessage = action.payload;
    },
    setIsStarterQuestion(state, action: PayloadAction<boolean>) {
      state.isStarterQuestion = action.payload;
    },
    setMute(state, action: PayloadAction<boolean | null>) {
      state.mute = action.payload;
    },
    setIsRecording(state, action: PayloadAction<boolean>) {
      state.isRecording = action.payload;
    },
    setIsInputDisabled(state, action: PayloadAction<boolean>) {
      state.isInputDisabled = action.payload;
    },
    setIsStreaming(state, action: PayloadAction<boolean>) {
      state.isStreaming = action.payload;
    },
    setIsStreamingLoading(state, action: PayloadAction<boolean>) {
      state.isStreamingLoading = action.payload;
    },
    setIsStartHelp(state, action: PayloadAction<boolean>) {
      state.isStartHelp = action.payload;
    },
    setSendButton(state, action: PayloadAction<boolean>) {
      state.sendButton = action.payload;
    },
    setMicroButton(state, action: PayloadAction<boolean>) {
      state.microButton = action.payload;
    },
    setIsReload(state, action: PayloadAction<boolean>) {
      state.isReload = action.payload;
    },
    clearChatHistory(state) {
      state.messages = [];
    },
    setIsSkipped(state, action: PayloadAction<boolean>) {
      state.isSkipped = action.payload;
    },
    setRecordFaild(state, action: PayloadAction<boolean>) {
      state.recordFailed = action.payload;
    },
    setRecordFade(state, action: PayloadAction<boolean>) {
      state.recordFade = action.payload;
    },
    setAgree(state, action: PayloadAction<boolean>) {
      state.agree = action.payload;
    },
    setPrivacy(state, action: PayloadAction<boolean>) {
      state.privacy = action.payload;
    },
    setAgreeComp(state, action: PayloadAction<boolean>) {
      state.agreeComp = action.payload;
    },
  },
});

export const {
  setMessage,
  addMessageToChat,
  setIsThinking,
  setThreadId,
  setTypingMessage,
  setIsStarterQuestion,
  setMute,
  setIsRecording,
  setIsInputDisabled,
  setIsStreaming,
  setIsStreamingLoading,
  setIsStartHelp,
  setSendButton,
  setMicroButton,
  clearChatHistory,
  setIsReload,
  setIsSkipped,
  setRecordFaild,
  setRecordFade,
  setAgree,
  setPrivacy,
  setAgreeComp,
} = chatSlice.actions;

export default chatSlice.reducer;
