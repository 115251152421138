import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setButtonActive, startVolume } from '../../../store/stream/stream-slice';
import { RootState, AppDispatch } from '../../../store/store';
import { establishConnection } from '../../../store/stream/stream-operations';

interface AllowVolumeProps {
  setOverlayVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AllowVolume: React.FC<AllowVolumeProps> = ({ setOverlayVisible }) => {
  const dispatch: AppDispatch = useDispatch();
  const [isCookieBannerVisible, setCookieBannerVisible] = useState(true);

  const handleAcceptVolume = () => {
    setCookieBannerVisible(false);
    setOverlayVisible(false);
    dispatch(establishConnection());
    dispatch(startVolume(true));
  };

  return (
    <>
      {isCookieBannerVisible && (
        <div className="cookie-banner" id="cookie-banner">
          <span>Разрешить воспроизводить звук.</span>
          <button id="connect-button" onClick={handleAcceptVolume}>
            Разрешить
          </button>
        </div>
      )}
    </>
  );
};

export default AllowVolume;
