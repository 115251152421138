import React from 'react';
import styles from './BackButton.module.scss';

interface BackButtonProps {
  href: string;
  altText?: string;
  skeleton?: string;
  disabled?: boolean;
  active?: boolean;
  className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
                                                 href,
                                                 altText = 'Back',
                                                 skeleton = '',
                                                 disabled = false,
                                                 active = false,
                                                 className = ''
                                               }) => {
  const buttonClasses = [
    styles.button,
    skeleton ? styles.skeleton : '',
    disabled ? styles.disabled : '',
    active ? styles.active : '',
    className
  ].filter(Boolean).join(' ');

  return (
    <a
      href={disabled ? undefined : href}
      className={buttonClasses}
      aria-label={altText}
      aria-disabled={disabled}
    >
      <div className={styles.icon}></div>
    </a>
  );
};

export default BackButton;