import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../store/store';
import { setButtonActive, startVolume } from '../../../store/stream/stream-slice';
import { setIsStreamingLoading, setMicroButton } from '../../../store/chat/chat-slice';

const VideoWrapper: React.FC = () => {
  const startVideoRef = useRef<HTMLVideoElement | null>(null);
  const bodyVideoRef = useRef<HTMLVideoElement | null>(null);
  const talkVideoRef = useRef<HTMLVideoElement | null>(null); // Видео для стрима
  const dispatch: AppDispatch = useDispatch();
  const isVolume = useSelector((state: RootState) => state.stream.isStartVolume);
  const isStreaming = useSelector((state: RootState) => state.stream.isStreaming);

  const [isStreamPlaying, setIsStreamPlaying] = useState(false); // Показ стрима
  const [isTalkVideoActive, setIsTalkVideoActive] = useState(false); // Состояние для активации talk-video

  useEffect(() => {
    if (startVideoRef.current && isVolume) {
      startVideoRef.current.play();
    }
  }, [isVolume]);

  // Обработчик окончания воспроизведения стартового видео
  const handleVideoEnd = () => {
    if (bodyVideoRef.current) {
      bodyVideoRef.current.classList.remove('hidden');
      bodyVideoRef.current.play(); // Запускаем основное видео
    }
    if (startVideoRef.current) {
      startVideoRef.current.classList.add('hidden');
      dispatch(setIsStreamingLoading(false));
      dispatch(setMicroButton(true));
      dispatch(setButtonActive(false)); // Скрываем стартовое видео
    }
  };

  // Обработчик окончания стрима (talk-video)
  const handleTalkVideoEnd = () => {
    setIsStreamPlaying(false); // Отключаем флаг стрима
    setIsTalkVideoActive(false); // Деактивируем класс active для стрим видео

    // Возвращаем основное видео
    if (bodyVideoRef.current) {
      bodyVideoRef.current.play();
    }
  };

  useEffect(() => {
    if (isStreaming && talkVideoRef.current) {
      setIsStreamPlaying(true); // Включаем стрим

      // Ожидаем завершения основного видео с задержкой, чтобы плавно перейти к стриму
      setTimeout(() => {
        if (bodyVideoRef.current) {
          bodyVideoRef.current.pause(); // Останавливаем основное видео
        }

        // Включаем видео стрима через 3 секунды
        setIsTalkVideoActive(true);
        if (talkVideoRef.current) {
          talkVideoRef.current.play();
        }
      }, 3000); // Задержка 3 секунды перед стримом
    } else if (!isStreaming && isStreamPlaying) {
      // Если стрим завершен
      setIsStreamPlaying(false);
      setIsTalkVideoActive(false); // Деактивируем talk-video

      // Восстанавливаем воспроизведение основного видео
      if (bodyVideoRef.current) {
        bodyVideoRef.current.play();
      }
    }
  }, [isStreaming]);

  useEffect(() => {
    if (startVideoRef.current) {
      startVideoRef.current.addEventListener('ended', handleVideoEnd);
    }

    if (talkVideoRef.current) {
      talkVideoRef.current.addEventListener('ended', handleTalkVideoEnd); // Обработчик окончания стрима
    }

    return () => {
      // Удаляем обработчики при размонтировании
      if (startVideoRef.current) {
        startVideoRef.current.removeEventListener('ended', handleVideoEnd);
      }
      if (talkVideoRef.current) {
        talkVideoRef.current.removeEventListener('ended', handleTalkVideoEnd);
      }
    };
  }, []);

  return (
    <div id="video-wrapper">
      <video
        id="talk-video"
        className={isTalkVideoActive ? 'talk-video active' : 'talk-video'}
        ref={talkVideoRef}
        playsInline
      ></video>
      <video
        id="start-video"
        className="start-video"
        ref={startVideoRef}
        src="videos/hello.mp4"
        autoPlay
        playsInline
      ></video>
      <video
        id="video-body"
        className={isStreaming ? 'video-body hidden' : 'video-body'}
        ref={bodyVideoRef}
        src="videos/body.mp4"
        autoPlay
        muted
        loop
        playsInline
      ></video>
    </div>
  );
};

export default VideoWrapper;
