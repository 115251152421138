// chat-selectors.tsx
import { RootState } from 'store/store';
import { ChatState } from './ChatTypes';

// Chat selectors
export const getChatMessages = (state: RootState): ChatState['messages'] => state.chat.messages;
export const getIsThinking = (state: RootState): ChatState['isThinking'] => state.chat.isThinking;
export const getThreadId = (state: RootState): ChatState['threadId'] => state.chat.threadId;
export const getTypingMessage = (state: RootState): ChatState['typingMessage'] => state.chat.typingMessage;
export const getIsStarterQuestion = (state: RootState): ChatState['isStarterQuestion'] => state.chat.isStarterQuestion;
export const getMute = (state: RootState): ChatState['mute'] => state.chat.mute;
export const getIsRecording = (state: RootState): ChatState['isRecording'] => state.chat.isRecording;
export const getIsStreamingLoading = (state: RootState): ChatState['isStreamingLoading'] => state.chat.isStreamingLoading;
export const getIsStartHelp = (state: RootState): ChatState['isStartHelp'] => state.chat.isStartHelp;
export const getSendButton = (state: RootState): ChatState['sendButton'] => state.chat.sendButton;
export const getMicroButton = (state: RootState): ChatState['microButton'] => state.chat.microButton;
export const getIsSkipped = (state: RootState):ChatState['isSkipped'] => state.chat.isSkipped;
export const getRecordFaild = (state: RootState):ChatState['recordFailed'] => state.chat.recordFailed;
export const getRecordFade = (state: RootState):ChatState['recordFade'] => state.chat.recordFade;
export const getAgree = (state: RootState):ChatState['agree'] => state.chat.agree;
export const getPrivacy = (state: RootState):ChatState['privacy'] => state.chat.privacy;
export const getAgreeComp = (state: RootState):ChatState['agreeComp'] => state.chat.agreeComp;
